/* eslint-disable react/jsx-filename-extension */

export const ADMINCODE = 'G000001';

export const PUBLIC_PATH = '/admin';

export const selfInfoPath = '/system/user';

export const ROOT_LOGIN = '/rootLogin';

export const FULLSCREEN_POINT = 'mainContent';

export const ORG_DATA_PERM_TYPE = 1;

export const AREA_DATA_PERM_TYPE = 2;

export const AREA_PERMS_OPTIONS = [
  {label: '全部区划', value: 1},
  {label: '所属区划及子区划', value: 2},
  {label: '所属区划', value: 3},
];

export const ORG_PERMS_OPTIONS = [
  {label: '全部组织', value: 1},
  {label: '所属组织及子组织', value: 2},
  {label: '所属组织', value: 3},
  {label: '自己', value: 4},
];

export const DATA_PERM_MAP = new Map([
  [ORG_DATA_PERM_TYPE, {text: '组织架构', options: ORG_PERMS_OPTIONS}],
  [AREA_DATA_PERM_TYPE, {text: '区划类型', options: AREA_PERMS_OPTIONS}],
]);

export const SYSTEM_LIST_MAP = new Map([
  [1, {title: '智慧统计', key: 1}],
  [2, {title: '数图新治', key: 2}],
  [3, {title: '机器人', key: 3}],
  [4, {title: '一企一册', key: 4}],
]);

export const AREA_TYPE_OPTIONS = [
  {label: '无', value: '0'},
  {label: '行政区', value: '1'},
  {label: '片区', value: '2'},
  {label: '园区', value: '3'},
  {label: '关区', value: '4'},
];

/** @type {Array<import('./types').RouteItem>} */
export const whiteRouters = [
  {
    code: 'system',
    parentCode: '-1',
    name: '个人中心',
    nodeInfo: {
      routeUrl: '/user',
      compUrl: 'containers/SystemManage/PersonalCenter',
    },
  },
  {
    code: 'notFound',
    parentCode: '-1',
    name: '404',
    nodeInfo: {
      routeUrl: '/404',
      compUrl: 'containers/NotFoundPage',
    },
  },
  {
    code: 'system',
    parentCode: '-1',
    name: '视窗',
    nodeInfo: {
      routeUrl: '/iFrameWindow',
      compUrl: 'containers/IFrameWindow',
    },
  },
];

export const notNeedGetPermByMenu = ['/system/user', '/notFound/404'];

export const needTwiceCheckUrl = [
  // '/system/setting',
  // '/system/accountManage',
  // '/system/menusManage',
  // '/config',
  // '/organizationManage',
  // '/rolesManage',
];

/**
 * 页面code表
 */
/* export const USER_PERMS_MAP = new Map([
  ['首页', 'P000001'],

  ['元数据(字典)管理', 'P000002'],

  ['采集任务', 'P000003'],

  ['数据处理逻辑配置', 'P000004'],

  ['系统设置', 'P000005'],

  ['账号管理', 'P000006'],

  ['组件管理', 'P000007'],

  ['用户管理', 'P000008'],

  ['菜单管理', 'P000009'],

  ['个人中心', 'P000010'],

  ['提示管理', 'P000011'],

  ['数据库表管理', 'P000101'],

  ['计量单位管理', 'P000104'],

  ['计算特征管理', 'P000105'],

  ['区划代码管理-版本表单', 'P000106'],

  ['宏观分组管理', 'P000109'],

  ['宏观指标管理', 'P000110'],

  ['微观数据查询', 'P000114'],
]); */
export const USER_PERMS_MAP = new Map([
  ['菜单管理', 'M000002'],

  ['系统设置', 'M000003'],

  ['账号管理', 'M000004'],

  ['角色管理', 'M000005'],

  ['组织管理', 'M000006'],

  ['组织用户', 'M000033'],
]);

/**
 * 功能权限表
 */
export const BUTTON_PERMS_MAP = new Map([
  // ['组织管理-启停组织', 'B000155'],
  // ['组织管理-修改信息', 'B000156'],
  // ['组织管理-操作日志', 'B000157'],
  // ['组织管理-删除组织', 'B000158'],
  // ['组织管理-新建组织', 'B000159'],
  // ['组织管理-组织用户', 'B000160'],
  // ['组织管理-组织排序按钮', 'B000161'],

  // ['角色管理-编辑角色', 'B000162'],
  // ['角色管理-删除角色', 'B000163'],
  // ['角色管理-角色用户', 'B000164'],
  // ['角色管理-新建角色', 'B000165'],

  // ['账户管理-修改信息', 'B000001'],
  // ['账户管理-日志查询', 'B000007'],
  // ['账户管理-新建账号', 'B000010'],
  // ['账户管理-启停账号', 'B000011'],
  // ['账户管理-密码重置', 'B000012'],
  // ['账户管理-关联分组', 'B000013'],
  // ['账户管理-关联角色', 'B000014'],
  // ['账户管理-权限查询', 'B000015'],

  ['数据库表管理-添加数据实例', 'B000104'],
  ['数据库表管理-刷新', 'B000105'],
  ['数据库表管理-设置', 'B000126'],
  ['数据库表管理-数据库', 'B000127'],
  ['数据库表管理-实例删除', 'B000128'],
  ['数据库表管理-数据表', 'B000129'],
  ['数据库表管理-数据库删除', 'B000130'],

  ['系统通知-重置筛选', 'B000106'],
  ['系统通知-数据查询导出上限管理', 'B000151'],

  ['计量单位-添加计量单位', 'B000107'],
  ['计量单位-导出配置', 'B000122'],
  ['计量单位-导入配置', 'B000123'],
  ['计量单位-设置', 'B000124'],
  ['计量单位-删除', 'B000125'],

  ['计算特征管理-添加计算特征组', 'B000110'],
  ['计算特征管理-导入配置', 'B000111'],
  ['计算特征管理-导出配置', 'B000112'],
  ['计算特征管理-设置', 'B000113'],
  ['计算特征管理-删除', 'B000114'],

  ['区划代码管理-版本表单-添加新版本', 'B000115'],
  ['区划代码管理-版本表单-导出配置', 'B000116'],
  ['区划代码管理-版本表单-导入配置', 'B000117'],
  ['区划代码管理-版本表单-编辑', 'B000119'],
  ['区划代码管理-映射编辑表单-映射-导入数据', 'B000120'],
  ['区划代码管理-代码编辑表单-代码表-导入数据', 'B000121'],

  ['宏观分组管理-添加分组', 'B000131'],
  ['宏观分组管理-导入配置', 'B000132'],
  ['宏观分组管理-导出配置', 'B000133'],
  ['宏观分组管理-设置', 'B000134'],
  ['宏观分组管理-删除', 'B000135'],

  ['宏观指标管理-添加指标', 'B000136'],
  ['宏观指标管理-导出配置', 'B000137'],
  ['宏观指标管理-导入配置', 'B000138'],
  ['宏观指标管理-编辑', 'B000139'],
  ['宏观指标管理-别名', 'B000140'],
  ['宏观指标管理-删除', 'B000141'],
  ['宏观指标管理-搜索框', 'B000142'],

  ['采集任务-编辑任务', 'B000101'],

  ['数据处理逻辑配置-修改配置', 'B000102'],
  ['数据处理逻辑配置-导入导出配置', 'B000103'],

  ['元数据(字典)管理-新增元数据', 'B000143'],
  ['元数据(字典)管理-删除元数据', 'B000144'],
  ['元数据(字典)管理-导入元数据', 'B000145'],
  ['元数据(字典)管理-导出元数据', 'B000146'],
  ['元数据(字典)管理-编辑元数据', 'B000147'],

  ['微观指标表管理-微观数据表编辑', 'B000148'],

  ['微观数据查询-标签编辑', 'B000152'],

  ['手工采集-新建报表', 'B000153'],
  ['手工采集-新建手册', 'B000154'],

  /* 开始 新用户中心按钮权限定义，来源：王勇权 */
  ['系统菜单-新建菜单', 'M000007'],
  ['系统菜单-编辑菜单', 'M000008'],
  ['系统菜单-菜单删除', 'M000009'],
  ['系统菜单-顺序调整', 'M000010'],

  ['菜单方案-新增方案', 'M000011'],
  ['菜单方案-新增菜单', 'M000012'],
  ['菜单方案-名称编辑', 'M000013'],
  ['菜单方案-方案删除', 'M000014'],
  ['菜单方案-顺序调整', 'M000015'],
  ['菜单方案-编辑方案', 'M000016'],
  ['菜单方案-菜单删除', 'M000017'],

  ['账号管理-新建账号', 'M000018'],
  ['账号管理-修改信息', 'M000019'],
  ['账号管理-权限查询', 'M000020'],
  ['账号管理-关联组织', 'M000021'],
  ['账号管理-关联角色', 'M000022'],
  ['账号管理-菜单方案', 'M000023'],
  ['账号管理-启停账号', 'M000024'],

  ['角色管理-新建角色', 'M000025'],
  ['角色管理-编辑角色', 'M000026'],
  ['角色管理-删除角色', 'M000027'],
  ['角色管理-角色用户', 'M000028'],

  ['组织管理-新建组织', 'M000029'],
  ['组织管理-编辑组织', 'M000030'],
  ['组织管理-删除组织', 'M000031'],
  ['组织管理-组织用户', 'M000035'],
  ['组织管理-启停组织', 'M000032'],

  /* 结束 新用户中心按钮权限定义，来源：王勇权 */
]);
