import {Global as GlobalStyle} from '@emotion/react';
import loadComponent from '@loadable/component';
import {useAtom} from 'jotai';
import * as React from 'react';
import {Route, Routes} from 'react-router-dom';

import {authMenusAtom} from './atoms/authMenus';
import RouteGuard from './components/RouteGuard';
import HomePage from './containers/HomePage';
import IFrameWindow from './containers/IFrameWindow';
import Layout from './containers/Layout';
import {flattenMenus} from './containers/Layout/helpers/disposeMenu';
import LoginPage from './containers/LoginPage';
import PersonalCenter from './containers/SystemManage/PersonalCenter';
import {
  customNotification,
  electronOnly,
  layout,
  overrideAntd,
  rebase,
  theming,
} from './global.css';

const renderRoutes = (routes) => {
  return routes.map((item) => {
    const {parentPageCode, code, nodeInfo} = item;
    const routePath = `/${parentPageCode ?? code}${nodeInfo.routeUrl}`;
    const RouteComponent = nodeInfo.compUrl
      ? loadComponent(() => import(`./${nodeInfo.compUrl}`))
      : PersonalCenter;

    return (
      <Route element={<RouteComponent />} key={routePath} path={routePath} />
    );
  });
};

function App() {
  const [authMenus] = useAtom(authMenusAtom);
  const dynamicRoutes = React.useMemo(() => {
    if (!authMenus.length) return null;
    return renderRoutes(flattenMenus(authMenus));
  }, [authMenus]);

  return authMenus ? (
    <>
      <Routes>
        <Route element={<Layout />} path='/'>
          {dynamicRoutes}
          <Route element={<HomePage />} path='/' />
          <Route element={<PersonalCenter />} path='*' />
          <Route element={<IFrameWindow />} path='/iFrameWindow' />
        </Route>

        <Route element={<LoginPage />} path='/login' />
        <Route element={<LoginPage />} path='/rootLogin' />
      </Routes>

      <RouteGuard />

      <GlobalStyle
        styles={[
          rebase,
          layout,
          theming,
          overrideAntd,
          electronOnly,
          customNotification,
        ]}
      />
    </>
  ) : null;
}

export default App;
