import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import {useMount} from 'react-use';

import {fetchSysSettingAtom} from '../../atoms/sysSettingAtom';
import SysFooter from '../../components/SysFooter';
import {storageKeys} from '../../constants/storageKeys';
import LoginForm from './LoginForm/index';

const footerWraper = css`
  position: absolute;
  bottom: 1px;
  color: #ffffff;
  width: 100%;
  text-align: center;
`;

export default function LoginPage() {
  const [sysSettingDetail] = useAtom(fetchSysSettingAtom);

  const loginPage = css`
    background: #212534 url(${sysSettingDetail.cover}) no-repeat fixed center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-app-region: drag;
  `;

  useMount(() => {
    window.localStorage.removeItem(storageKeys.TOKEN);
  });

  return (
    <div className='loginPage' css={loginPage}>
      <div
        css={css`
          z-index: 1;
          position: absolute;
          right: 60px;
        `}
      >
        <LoginForm />
      </div>

      <div css={footerWraper}>
        <SysFooter />
      </div>
    </div>
  );
}
