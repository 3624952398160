import {Layout as ALayout, Skeleton} from 'antd';
import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import {getGuanHuLoginedToken} from '../../api/login';
import {contentCSS} from './layout.css';

export default function Layout({children, ...rest}) {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      const frameUrl = decodeURIComponent(searchParams.get('frameUrl'));
      const guanhucode = decodeURIComponent(searchParams.get('guanhucode'));

      if (guanhucode && frameUrl) {
        // 有guanhucode参数，说明是从观湖系统跳转进来，请求token后再跳转到数图
        const tokenRes = await getGuanHuLoginedToken({code: guanhucode});
        const jumpUrl = `${frameUrl}&STXZ_TOKEN=${tokenRes?.data ?? ''}`;

        window.location.replace(jumpUrl);
      } else {
        console.error('err: ', frameUrl, guanhucode);
      }
    })();
  }, [searchParams]);

  return (
    <ALayout {...rest}>
      <ALayout.Content css={contentCSS}>
        <Skeleton active title={{width: '100%'}} />
      </ALayout.Content>
    </ALayout>
  );
}
